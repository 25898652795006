import React, { FunctionComponent } from 'react';
import styles from './homeBlock.module.css';
import { Calculator } from '../calculator';
import { Translation } from '../../../translations/translation';
import { useConfigData } from 'hooks/useConfigData';
// import trustpilotImage from '../../../images/icons/trustpilot.svg';
// import { AnchorButton } from '../../../components/common/button';

export const HomeBlock: FunctionComponent = () => {
  const { data } = useConfigData();
  return (
    <div className={styles.homeBlock}>
      <div className={styles.homeBlock__contentWrapper}>
        <div className={styles.homeBlock__content}>
          <h1 className={'h1 h1_noMargin'}>
            <Translation k={'Instantly buy and sell crypto!'} />
          </h1>
          <div className={`${styles.homeBlock__text} text`}>
            <p>
              <Translation
                k={'Moniv.io is a simple and secure place to trade'}
              />
            </p>
          </div>
        </div>
        <div className={styles.homeBlock__footer}>
          {data ? (
            <div className={styles.homeBlock__trustpilot}>
              <div className={styles.homeBlock__trustpilotRow}>
                <div className={styles.homeBlock__trustpilotRating}>
                  1,345,000
                </div>
                users trust us
              </div>
            </div>
          ) : null}
          {/* <div className={styles.homeBlock__actionWrapper}>
            {data?.createAccountLink ? (
              <a
                href={'https://moniv.io/registration'}
                // href={data.createAccountLink.path}
                target={data.createAccountLink.target || undefined}
                rel={
                  data.createAccountLink.target === '_blank'
                    ? 'noopener noreferrer'
                    : undefined
                }
                className={styles.homeBlock__action}
              >
                <svg
                  width={136}
                  height={136}
                  viewBox="0 0 136 136"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={'none'}
                  className={styles.homeBlock__circle}
                >
                  <circle
                    cx="68"
                    cy="68"
                    r="67"
                    stroke={'currentColor'}
                    strokeDasharray={2 * Math.PI * 67}
                    strokeWidth={1}
                  />
                </svg>
                <span>
                  <Translation k={data.createAccountLink.key} />
                </span>
                <div className={styles.homeBlock__actionIcon}>
                  <svg
                    width={20}
                    height={16}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 8L18 8"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                    />
                  </svg>
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.08008 1.07996L5.58008 5.57996L1.08008 10.08"
                      stroke="black"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
              </a>
            ) : null}
          </div> */}
        </div>
        {/* {data ? (
          <div className={styles.homeBlock__actions}>
            {data.headerSections.auth.map((button, index) => {
              return (
                <AnchorButton
                  href={button.path}
                  outline={index === 0}
                  outlineColor={index !== 0}
                  target={button.target}
                  rel={
                    button.target === '_blank'
                      ? 'noopener noreferrer'
                      : undefined
                  }
                  key={index}
                  icon={
                    index !== 0 ? (
                      <svg width="19" height="9" viewBox="0 0 19 9">
                        <use href="#arrowRightSmall" />
                      </svg>
                    ) : undefined
                  }
                >
                  <Translation k={button.key} />
                </AnchorButton>
              );
            })}
          </div>
        ) : null} */}
      </div>
      <div>
        <div className={styles.homeBlock__calculator}>
          <Calculator />
        </div>
      </div>
    </div>
  );
};
