import { FunctionComponent } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser, TextBlock } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
// import { useLanguage } from '../../store/slices/common/selectors';
// import { useGetLimitsQuery } from '../../store/api/limits';
import { Loader } from '../../components/common/loader';

// const markdown = `This is a contract between you and VOLRIX OU, a private limited company
//         incorporated in Estonia under registration number 14897904 and whose
//         registered office is Osmussaare street 8, Tallinn, Estonia, 13811.
//         References in these Terms to "VOLRIX", "we", "our" or "us", are to
//         VOLRIX OU and references to "you" or "your" are to the physical person
//         or legal entity with whom VOLRIX enters into these Terms. Terms apply to
//         all visitors, users and others who access or use the virtual currency
//         exchange services (“Services”).
//
// | Tables   |      Are      |  Cool |
// |----------|:-------------:|------:|
// | col 1 is |  left-aligned | $1600 |
// | col 2 is |    centered   |   $12 |
// | col 3 is | right-aligned |    $1 |
//
// We are authorised by the Estonian
//         Financial Intelligence Unit (FIU), which is an independent structural
//         unit of the Estonian Police and Border Guard Board, and that allow to
//         provide Services under our license number FVT000016.
//
// By signing up to
//         use an account through www.Moniv.io, or any of our associated
//         websites, APIs, or mobile applications, chatbots (collectively the
//         "VOLRIX Site"), you agree that you have read, understood, and accept all
//         of the terms and conditions contained in these [Terms](/terms), as well as our
//         PRIVACY POLICY, KYC/AML POLICY and REFUND POLICY.
//
// You should be aware
//         that the risk of loss in trading or holding Virtual Currencies may be
//         substantial. Please note that our Services are only regulated for money
//         laundering purposes. You should therefore carefully consider whether
//         trading or holding Virtual Currencies is suitable for you in light of
//         your financial condition.
//
// ## 1. Eligibility
//
// - You must be at least 18 years old to use the Services.
// - You must be a legal resident of the European Union or the European
//           Economic Area.
// - You must not be a “Specially Designated National” of the European
//           Union or the European Economic Area.
//         `;

import data from '../../translations/dictionary.json';

export const Limits: FunctionComponent = () => {
  // const lang = useLanguage();
  // const { data } = useGetLimitsQuery({ lang: lang || 'en' });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  return (
    <Main>
      <PageHeader
        subtitle={data['limits-subtitle']}
        title={data['limits-title']}
      >
        <MarkdownParser text={data['limits-titleContent']} />
      </PageHeader>
      <Container>
        <TextGrid>
          <TextBlock text={data['limits-content']} markdown />
        </TextGrid>
      </Container>
    </Main>
  );
};
